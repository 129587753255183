<template>
  <app-page
    ref="page"
    :list="list"
    :creator="creator"
    @cell-click="onCellClcik"
    @clear-item="onClearItem"
    @data-change="onDataChange"
  >
    <template #header>
      <div class="toolbar">
        <el-alert type="warning" :closable="false" :title="title" />
        <div class="action">
          <el-radio-group
            class="group"
            fill="#E6A23C"
            :value="list.params.canteenType"
            @input="onCanteenTypeChange"
          >
<!--            <el-radio-button :label="1">一食堂</el-radio-button>-->
<!--            <el-radio-button :label="2">二食堂</el-radio-button>-->
            <el-radio-button :disabled="canteenType === 2 && canteenType !== 999 " :label="1">一食堂</el-radio-button>
            <el-radio-button :disabled="canteenType === 1 && canteenType !== 999 " :label="2">二食堂</el-radio-button>
          </el-radio-group>
          <el-button-group class="group">
            <el-button
              :loading="loading"
              icon="el-icon-arrow-left"
              @click="onPrevious"
            >
              上周
            </el-button>
            <el-button
              :loading="loading"
              icon="el-icon-arrow-up"
              @click="onCurrent"
            >
              返回
            </el-button>
            <el-button
              :loading="loading"
              icon="el-icon-arrow-right"
              @click="onNext"
            >
              下周
            </el-button>
          </el-button-group>
          <el-button-group class="group">
            <el-button
              :loading="loading"
              :disabled="disabled"
              icon="el-icon-check"
              @click="onSave"
            >
              保存
            </el-button>
            <el-button
              :loading="loading"
              :disabled="disabled"
              icon="el-icon-finished"
              type="primary"
              @click="onPublish"
            >
              发布
            </el-button>
          </el-button-group>
        </div>
      </div>
    </template>
    <template #dialog>
      <app-menu-dialogs
        :visible.sync="dialog.visible"
        show-other
        v-bind="dialog"
        @select="onSelect"
      />
      <app-number-dialog
        :visible.sync="number.visible"
        v-bind="number"
        @cancel="onNumberCancel"
        @confirm="onNumberConfirm"
      />
    </template>
  </app-page>
</template>

<script>
import mixins from './mixins';

export default {
  name: 'Takeout',
  mixins
};
</script>

<style lang="scss" scoped>
.toolbar {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: white;

  .title {
    display: flex;
    align-items: center;
  }

  .action {
    margin-top: 12px;

    .group {
      margin-right: 12px;
    }
  }
}

::v-deep {
  .el-table table {
    border: thin solid black;
    border-bottom: none;
    border-right: none;
  }

  .el-table__cell {
    border-bottom: thin solid black !important;
    border-right: thin solid black !important;
    cursor: pointer;
  }

  .lunch {
    background-color: #fde2e2;
  }

  .dinner {
    background-color: #e1f3d8;
  }

  .outed {
    color: #67c23a;
  }
}
</style>
