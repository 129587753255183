import dayjs from 'dayjs';
import request from '@/utils/request';

export default {
  data() {
    return {
      list: {
        pages: false,
        action: false,
        url: '/canteen/menu/getMenuList',
        method: 'post',
        params: {
          types: [5],
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
          startDate: dayjs()
            .add(1, 'week')
            .startOf('week')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .add(1, 'week')
            .endOf('week')
            .format('YYYY-MM-DD')
        },
        fields: this.getListFields(),
        spanMethod: this.spanMethod,
        highlightCurrentRow: false,
        rowClassName: this.rowClassName,
        cellClassName: this.cellClassName,
        customRequest: this.customRequest
      },
      items: [],
      canteenType:Number(sessionStorage.getItem('canteenType'))
    };
  },
  methods: {
    // 表格字段定义
    getListFields() {
      return [
        { label: '类型', prop: 'type', width: 100, align: 'center' },
        {
          label: '星期一',
          prop: '0.name',
          width: 180,
          align: 'center',
          type: 'takeout',
          index: 0,
          showOther: true
        },
        {
          label: '星期二',
          prop: '1.name',
          width: 180,
          align: 'center',
          type: 'takeout',
          index: 1,
          showOther: true
        },
        {
          label: '星期三',
          prop: '2.name',
          width: 180,
          align: 'center',
          type: 'takeout',
          index: 2,
          showOther: true
        },
        {
          label: '星期四',
          prop: '3.name',
          width: 180,
          align: 'center',
          type: 'takeout',
          index: 3,
          showOther: true
        },
        {
          label: '星期五',
          prop: '4.name',
          width: 180,
          align: 'center',
          type: 'takeout',
          index: 4,
          showOther: true
        },
        {
          label: '星期六',
          prop: '5.name',
          width: 180,
          align: 'center',
          type: 'takeout',
          index: 5,
          showOther: true
        },
        {
          label: '星期天',
          prop: '6.name',
          width: 180,
          align: 'center',
          type: 'takeout',
          index: 6,
          showOther: true
        }
      ];
    },
    // 数据变更
    onDataChange(items) {
      this.items = items;
    },
    // 单击菜单单元格，弹出选择对话框
    onCellClcik(row, column) {
      // 只能单击星期内的单元格
      const isName = column.property?.includes('name');
      if (isName) {
        // 匹配属性中的数字做为星期下标
        const index = column.index;
        const { dialog, number } = this;
        // 选中的单元格
        const selected = row[index];
        const { type, category, isOut } = selected;
        // 上架的不能再改了
        if (isOut == 1) {
          return;
        }
        // 分类
        dialog.type = type;
        // 类别
        dialog.category = category;
        // 显示窗口
        dialog.visible = true;
        // 缓存选中的菜单
        number.selected = selected;
      }
    },
    // 清空单元格菜单信息
    async onClearItem(row, field) {
      // 星期下标
      const index = field.index;
      const menuItem = row[index];
      const id = menuItem.id;
      if (id) {
        await request.post('/canteen/menu/delMenu', { id });
      }
      // 重置菜单
      menuItem.id = undefined;
      menuItem.picPath = undefined;
      menuItem.name = '';
      menuItem.price = 0;
      // menuItem.totalNum = 0;
      menuItem.isOut = 0;
    }
  }
};
