export default {
  methods: {
    // 合并单元格
    spanMethod({ rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        // 类型列
        const span = { 0: [3, 1], 3: [5, 1], 8: [5, 1] };
        return span[rowIndex] || [0, 0];
      } else {
        return undefined;
      }
    },
    // 行样式
    rowClassName({ rowIndex }) {
      if (rowIndex < 3) {
        return 'breakfast'; // 早餐样式
      } else if (rowIndex < 8) {
        return 'lunch'; // 中餐样式
      } else if (rowIndex < 13) {
        return 'dinner'; // 晚餐样式
      } else {
        return ''; // 其他样式
      }
    },
    // 单元格样式
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < 2) {
        return;
      }
      var cell = row[columnIndex - 2];
      if (cell?.isOut == 1) {
        return 'outed';
      }
    }
  }
};
