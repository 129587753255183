// 填充类别选项

export function categories() {
  return [
    undefined,
    [
      { label: '所有', value: undefined },
      { label: '粉', value: 1 },
      { label: '面', value: 2 },
      { label: '粥', value: 3 },
      { label: '面点、蛋', value: 4 },
      { label: '饮品', value: 5 },
      { label: '套餐', value: 6 },
      { label: '饭', value: 7 }
    ],
    [
      { label: '所有', value: undefined },
      { label: '主荤', value: 1 },
      { label: '副荤', value: 2 },
      { label: '素菜', value: 3 },
      { label: '例汤', value: 4 },
      { label: '营养', value: 5 },
      { label: '水饺', value: 6 }
    ],
    undefined,
    [
      { label: '所有', value: undefined },
      { label: '中午领取', value: 2 },
      { label: '晚上领取', value: 3 }
    ]
  ];
}

export default (e, { fields, model, rules }) => {
  // 类别选项
  const options = categories();
  // 类别字段
  const categoryField = fields.find(item => item.prop == 'category');
  // 重置
  model.category = undefined;
  // 填充类别
  categoryField.options = options[e];
  // 要显示和隐藏的字段
  const priceField = fields.find(item => item.prop == 'price');
  const descField = fields.find(item => item.prop == 'description');
  // 工具栏是没有这些定义的
  if (!priceField && !descField) {
    return;
  }
  // 选中外卖时
  if (e) {
    priceField.hidden = false;
    descField.hidden = false;
    // 打开校验
    rules.price.required = true;
    rules.description.required = true;
  } else {
    priceField.hidden = true;
    descField.hidden = true;
    // 关闭校验
    rules.price.required = false;
    rules.description.required = false;
  }
  categoryField.options = options[e];
};
