import request from '@/utils/request';
import { categories } from './category-field-change';

export default {
  data() {
    return {
      list: {
        mode: 'new',
        url: '/canteenCookbook',
        defaultPageSize: 50,
        params: {isSingle:1}
      }
    };
  },
  methods: {
    // 选中编辑
    onSelect(e) {
      const { updater, $refs } = this;
      // 设置表单模型
      updater.model = e;
      updater.model.isUpdate = true
      // 填充类别选项
      const options = categories()[e.type];
      updater.fields[2].options = options;
      const { rules } = updater;
      if (e.type) {
        // 显示外卖字段
        // 打开校验
        rules.price.required = true;
        rules.description.required = true;
      } else {
        // 关闭校验
        rules.price.required = false;
        rules.description.required = false;
      }
      $refs.page.onUpdating();
    },
    async onRemove(e) {
      await request.post('/canteenCookbook/remove', { ids: [e.id] });
      await this.$refs.page.getItems();
    }
  }
};
