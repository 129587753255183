import dayjs from 'dayjs';

export default {
  methods: {
    // 填充空白单元格
    fillItems(vm) {
      const { startDate, canteenType } = vm.params;
      const length = 13;
      // 中午有2行, 晚上有4行
      for (let i = 0; i < length; i++) {
        const category = this.fillCategory(i);
        // 行数据
        const row = { y: i };
        // 星期列
        for (let j = 0; j < 7; j++) {
          // 每个单元格
          const cell = {
            // 编号
            id: undefined,
            // 图片路径
            picPath: undefined,
            // 名称
            name: '',
            // 外卖
            type: 5,
            // 类别
            category,
            // 价格
            price: 0,
            // 总数量
            totalNum: 0,
            // 可选择最大数量，默认值
            maxNum: 2,
            // 发布日期
            menuDate: dayjs(startDate)
              .add(j, 'day')
              .format('YYYY-MM-DD'),
            // 下架状态（0下架，1上架 ）
            isOut: 0,
            // 细类
            isSet: 0,
            // 食堂
            canteenType,
            // X坐标
            tableX: j,
            // Y坐标
            tableY: i
          };
          row[j] = cell;
        }
        // 添加行
        vm.items.push(row);
      }
    },
    // 填充类别
    fillCategory(row) {
      if (row < 3) {
        return 1; // 早餐
      } else if (row < 8) { // 修改条件，添加中餐
        return 2; // 中餐
      } else {
        return 3; // 晚餐
      }
    },
    // 设置行头
    setRowHeader(vm) {
      // 餐型列
      vm.items[0].type = '早餐';
      vm.items[3].type = '中餐';
      vm.items[8].type = '晚餐';
    },
    // 设置列头
    setColumnHeader(vm) {
      const { params, fields } = vm;
      const { startDate } = params;
      // 从第3列开始
      const begin = 1;
      // 本周开始的日期
      for (let i = begin; i < fields.length; i++) {
        const field = fields[i];
        // 日期
        const date = dayjs(startDate)
          .add(i - begin, 'day')
          .format('YYYY-MM-DD');
        // 星期
        const week = dayjs(startDate)
          .add(i - begin, 'day')
          .format('ddd');
        // 设置表头文本
        field.label = `${date},${week}`;
      }
    }
  }
};
